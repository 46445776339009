<template>
  <div
    class="home"
    :style="'width:' + winWCur + 'px;height:' + winHCur + 'px;margin-top:' + winTCur + 'px'"
  >
    <div class="video1container">
      <img class="screen-img" src="../assets/imgs/01.jpg"/>
      <div class="btn101 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(1)"></div>
      <div class="btn102 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(2)"></div>
      <div class="btn103 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(3)"></div>
      <div class="btn104 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(4)"></div>
      <div class="btn105 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(5)"></div>
      <div class="btn106 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(6)"></div>
      <div class="btn107 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(7)"></div>
      <div class="btn108 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(8)"></div>
      <div class="btn109 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(9)"></div>
      <div class="btn110 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(10)"></div>
      <div class="btn111 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(11)"></div>
      <div class="btn112 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(12)"></div>
      <div class="btn113 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(13)"></div>
      <div class="btn114 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(14)"></div>
      <div class="btn115 cur" :class="{cur1: videosIdx>0 }" @click="clickVideo(15)"></div>
      <div class="video-box" v-if="videosIdx>0">
        <div class="video1container">
          <video
              v-if="videosIdx==1"
              class="video"
              ref="video1"
              muted
              autoplay
              @ended="videoEnd(1)"
              src="../assets/videos/1/13-13.mp4"
          ></video>
          <video
              v-if="videosIdx==2"
              class="video"
              ref="video2"
              muted
              autoplay
              @ended="videoEnd(2)"
              src="../assets/videos/1/14-14.mp4"
          ></video>
          <video
              v-if="videosIdx==3"
              class="video"
              ref="video3"
              muted
              autoplay
              @ended="videoEnd(3)"
              src="../assets/videos/1/15-15.mp4"
          ></video>
          <video
              v-if="videosIdx==4"
              class="video"
              ref="video4"
              muted
              autoplay
              @ended="videoEnd(4)"
              src="../assets/videos/1/10-10.mp4"
          ></video>
          <video
              v-if="videosIdx==5"
              class="video"
              ref="video5"
              muted
              autoplay
              @ended="videoEnd(5)"
              src="../assets/videos/1/12-12.mp4"
          ></video>
          <video
              v-if="videosIdx==6"
              class="video"
              ref="video6"
              muted
              autoplay
              @ended="videoEnd(6)"
              src="../assets/videos/1/11-11.mp4"
          ></video>
          <video
              v-if="videosIdx==7"
              class="video"
              ref="video7"
              muted
              autoplay
              @ended="videoEnd(7)"
              src="../assets/videos/1/7-7.mp4"
          ></video>
          <video
              v-if="videosIdx==8"
              class="video"
              ref="video8"
              muted
              autoplay
              @ended="videoEnd(8)"
              src="../assets/videos/1/8-8.mp4"
          ></video>
          <video
              v-if="videosIdx==9"
              class="video"
              ref="video9"
              muted
              autoplay
              @ended="videoEnd(9)"
              src="../assets/videos/1/9-9.mp4"
          ></video>
          <video
              v-if="videosIdx==10"
              class="video"
              ref="video10"
              muted
              autoplay
              @ended="videoEnd(10)"
              src="../assets/videos/1/1-1.mp4"
          ></video>
          <video
              v-if="videosIdx==11"
              class="video"
              ref="video11"
              muted
              autoplay
              @ended="videoEnd(11)"
              src="../assets/videos/1/2-2.mp4"
          ></video>
          <video
              v-if="videosIdx==12"
              class="video"
              ref="video12"
              muted
              autoplay
              @ended="videoEnd(12)"
              src="../assets/videos/1/3-3.mp4"
          ></video>
          <video
              v-if="videosIdx==13"
              class="video"
              ref="video13"
              muted
              autoplay
              @ended="videoEnd(13)"
              src="../assets/videos/1/6-6.mp4"
          ></video>
          <video
              v-if="videosIdx==14"
              class="video"
              ref="video14"
              muted
              autoplay
              @ended="videoEnd(14)"
              src="../assets/videos/1/5-5.mp4"
          ></video>
          <video
              v-if="videosIdx==15"
              class="video"
              ref="video15"
              muted
              autoplay
              @ended="videoEnd(15)"
              src="../assets/videos/1/4-4.mp4"
          ></video>
        </div>
      </div>
      <div class="btn-page1 highlight">
        <span class="btn-text" :style="'font-size:' + fontSize + 'px'">技术突破</span>
      </div>
      <div class="cur btn-page2" @click="$router.push({ path:'/2', query: $route.query})">
        <span class="btn-text" :style="'font-size:' + fontSize + 'px'">产品创新</span>
      </div>
      <div class="cur btn-page3" @click="$router.push({ path:'/3', query: $route.query})">
        <span class="btn-text" :style="'font-size:' + fontSize + 'px'">产业赋能</span>
      </div>
      <div class="block-warp" v-if="videosIdx>0" @click="close"></div>
    </div>
  </div>
</template>

<script>
import { myMixins } from "../assets/js/mixin";

export default {
  mixins: [myMixins],
  created() {
  },
  data() {
    return {
      videosIdx: 0
    }
  },
  computed: {
  },
  methods: {
    close() {
      console.log('close', this.videosIdx)
      this.videosIdx = 0
    },
    getVideoRef(idx) {
      return 'video' + String(idx)
    },
    clickVideo(idx) {
      console.log('clickVideo', idx)
      if (this.videosIdx > 0) {
        console.log('this.videosIdx > 0 return')
        this.close()
        return
      }
      if (idx === this.videosIdx) {
        console.log('clickVideo idx === this.videosIdx return')
        return
      }
      this.videosIdx = idx
    },
    videoEnd(val) {
      console.log('videoEnd', val)
    }
  }
};
</script>

<style scoped>
.btn101 {
  position: absolute;
  width: 7%;
  height: 4%;
  left: 14%;
  top: 29%;
}
.btn104 {
  position: absolute;
  width: 7%;
  height: 4%;
  left: 41%;
  top: 29%;
}
.btn107 {
  position: absolute;
  width: 7%;
  height: 4%;
  left: 69%;
  top: 29%;
}
.btn110 {
  position: absolute;
  width: 8%;
  height: 4%;
  left: 26.5%;
  top: 65%;
}
.btn113 {
  position: absolute;
  width: 7%;
  height: 4%;
  left: 54.5%;
  top: 65%;
}
.btn102 {
  position: absolute;
  width: 4%;
  height: 3%;
  left: 16.5%;
  top: 56%;
}
.btn103 {
  position: absolute;
  width: 4%;
  height: 3%;
  left: 28.5%;
  top: 56%;
}
.btn105 {
  position: absolute;
  width: 4%;
  height: 3%;
  left: 43%;
  top: 56%;
}
.btn106 {
  position: absolute;
  width: 4%;
  height: 3%;
  left: 55.5%;
  top: 56%;
}
.btn108 {
  position: absolute;
  width: 4%;
  height: 3%;
  left: 71%;
  top: 56%;
}
.btn109 {
  position: absolute;
  width: 4%;
  height: 3%;
  left: 83%;
  top: 56%;
}
.btn111 {
  position: absolute;
  width: 4%;
  height: 3%;
  left: 29.5%;
  top: 91.5%;
}
.btn112 {
  position: absolute;
  width: 4%;
  height: 3%;
  left: 41.5%;
  top: 91.5%;
}
.btn114 {
  position: absolute;
  width: 4%;
  height: 3%;
  left: 56.5%;
  top: 91.5%;
}
.btn115 {
  position: absolute;
  width: 4%;
  height: 3%;
  left: 68%;
  top: 91.5%;
}
</style>
